import { Controller } from "@hotwired/stimulus"
// import Tabulator from "tabulator-tables";

export default class extends Controller {
    static targets = [
        'accountStatementTable',
        'projectSearchTable',
        'projectCode',
        'username',
        'searchButton',
        'resultsHeading'
    ]

    submitEnabled = false

    connect() {
        this.buildAccountStatementTable()
        this.validateInput()
    }

    buildAccountStatementTable() {
        new Tabulator(this.accountStatementTableTarget, {
            ajaxURL: '/sam/account_statement',
            layout: 'fitDataStretch',
            columns: [
                { title: 'Project Code', field: 'projcode' },
                { title: 'Title', field: 'title' }
            ],
            rowClick: function(e, row) {
                const project = row._row.data.projcode
                window.location = `/sam/show?project_code=${project}`
            },
            rowFormatter: function (row) {
                row._row.element.classList.add('clickable')
            }
        })
    }

    validateInput() {
        const projCodeValue = this.projectCodeTarget.value
        const usernameValue = this.usernameTarget.value

        if (projCodeValue.length > 0 || usernameValue.length > 0) {
            this.enableSubmit()
        } else {
            this.disableSubmit()
        }
    }

    handleKeypress(e) {
        if (e.keyCode == 13 && this.submitEnabled) {
            this.searchProjectsByCode()
        }
    }

    enableSubmit() {
        this.searchButtonTarget.disabled = false
        this.searchButtonTarget.classList.remove('disabled')
        this.submitEnabled = true
    }

    disableSubmit() {
        this.submitEnabled = false
        this.searchButtonTarget.disabled = true
        this.searchButtonTarget.classList.add('disabled')
    }

    searchProjectsByCode() {
        const projCode = this.projectCodeTarget.value
        const username = this.usernameTarget.value

        this.showSpinner()
        setTimeout(() => this.hideSpinner(), 8000)

        const self = this

        new Tabulator(this.projectSearchTableTarget, {
            ajaxURL: `/sam/project_search?project_code=${projCode}&username=${username}`,
            ajaxResponse:function(url, params, response) {
                self.hideSpinner()

                return response;
            },
            ajaxError:function(xhr, textStatus, errorThrown){
                console.log(xhr)
                console.log(textStatus)
                console.log(errorThrown)
            },
            layout: 'fitDataStretch',
            columns: [
                { title: 'Project Code', field: 'projcode' },
                { title: 'Title', field: 'title' }
            ],
            rowClick: function(e, row) {
                const project = row._row.data.projcode
                window.location = `/sam/show?project_code=${project}`
            },
            rowFormatter: function (row) {
                row._row.element.classList.add('clickable')
            }
        })
    }

    showSpinner() {
        this.disableSubmit()
        this.searchButtonTarget.innerHTML = '<span class="spinner-border text-ncar-green loading-spinner mx-auto" role="status" id="loading-spinner"><span class="sr-only">Loading...</span></span>'
    }

    hideSpinner() {
        this.enableSubmit()
        this.searchButtonTarget.innerHTML = 'Search'
    }

    showResultsHeading() {
        this.resultsHeadingTarget.classList.remove('d-none')
    }

    buildWallclockTables() {
        const wallclockTable = document.getElementById('wallclock-table')

        if (wallclockTable) {
            new Tabulator(wallclockTable, {
                ajaxURL: `/sam/wallclock_exemptions`,
                layout: 'fitDataStretch',
                virtualDom: false,
                columns: [
                    {title: 'Resource', field: 'resourceName'},
                    {title: 'Queue', field: 'queue'},
                    {title: 'Start Date', field: 'startDate'},
                    {title: 'End Date', field: 'endDate'},
                    {title: 'Limit (Hours)', field: 'hourLimit'},
                    {title: 'Comment', field: 'comment'}
                ]
            })
        }
    }
}