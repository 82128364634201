import { Controller } from "@hotwired/stimulus"
// import Tabulator from "tabulator-tables";

export default class extends Controller {
    static targets = [ 'projectUsageTable' ]
    static values = { projectCode: String }

    connect() {
        this.buildProjectUsageTable()
    }

    buildProjectUsageTable() {
        let i = 1;
        let self = this;

        new Tabulator(this.projectUsageTableTarget, {
            ajaxURL: `/sam/project_usage_report?project_code=${this.projectCodeValue}`,
            layout: 'fitDataStretch',
            virtualDom: false,
            responsiveLayout: true,
            columns: [
                { title: 'Resource', field: 'resource_name', align:'center' },
                { title: 'Status', field: 'status', align:'center' },
                { title: 'Start', field: 'start', align:'center' },
                { title: 'End', field: 'end', align:'center' },
                { title: 'Allocation', field: 'allocation', align:'center' },
                { title: 'Usage', field: 'usage', align:'center' },
                { title: 'Adjustments', field: 'adjustments', align:'center' },
                { title: 'Balance', field: 'balance', align:'center' }
            ],
            rowFormatter: function(row) {
                if (row.getData().threshold_reports) {
                    row._row.element.firstElementChild.classList.add('clickable')
                    row._row.element.firstElementChild.innerHTML = '<i class="fa fa-minus-square mr-1"></i>' + row._row.element.firstElementChild.innerHTML

                    row._row.element.firstElementChild.addEventListener( 'click', () => {
                        const subTable = row._row.element.lastElementChild

                        if (subTable.style.display == 'block') {
                            subTable.style.display = 'none'
                            self.togglePlusIcon(row)
                        } else {
                            subTable.style.display = 'block'
                            self.toggleMinusIcon(row)
                        }
                    });

                    //create and style holder elements
                    var holderEl = document.createElement("div");
                    var tableEl = document.createElement("div");

                    holderEl.style.display = 'none'
                    holderEl.style.boxSizing = "border-box";
                    holderEl.style.padding = "10px 30px 10px 10px";
                    holderEl.style.borderTop = "1px solid #333";
                    holderEl.style.borderBotom = "1px solid #333";
                    holderEl.style.background = "#ddd";
                    holderEl.setAttribute('id', `sub-table-${i}`)

                    tableEl.style.border = "1px solid #333";

                    holderEl.appendChild(tableEl);
                    row.getElement().appendChild(holderEl);

                    new Tabulator(tableEl, {
                        layout:"fitColumns",
                        data:row.getData().threshold_reports,
                        columns: [
                            { title: 'Period', field: 'label', align:'center' },
                            { title: 'Allocation', field: 'allocationAmount', align:'center' },
                            { title: 'Charges', field: 'charges', align:'center' },
                            { title: 'Percent Usage', field: 'percentUsage', align:'center' },
                        ]
                    })

                    i++
                }

                const hasSubTable = row._row.element.lastElementChild.id.includes('sub-table')

                if (hasSubTable) {
                    const subTable = row._row.element.lastElementChild

                    subTable.style.display = 'block'
                }
            }
        })
    }

    toggleMinusIcon(row) {
        let icon = row._row.element.querySelectorAll('.svg-inline--fa')[0]

        icon.classList.remove('fa-plus-square')
        icon.classList.add('fa-minus-square')
    }

    togglePlusIcon(row) {
        let icon = row._row.element.querySelectorAll('.svg-inline--fa')[0]

        icon.classList.remove('fa-minus-square')
        icon.classList.add('fa-plus-square')
    }
}